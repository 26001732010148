import React from 'react';

import './index.css';

import Items from './faq';
import FaqItem from "./faq-item";

class Faq extends React.Component {
    constructor(props) {
        super();
    }

    render() {

        const faqItems = Items.map((item, idx) => {
            return <FaqItem key={idx} data={item} />
        });

        return(
            <div className="faq_wrapper">
                <div className="content_centrer">
                    <h1>Ответы на вопросы</h1>
                    <h6>Все еще сомневаетесь? Наши ответы помогут вам принять решение.</h6>

                    <div className="faq_list">
                        {faqItems}
                    </div>
                </div>
            </div>
        )
    }
}

export default Faq;