import React from 'react';
import img from './images/buratino.png';

class Buratino extends React.Component {

    render() {
        return (
            <div className="plate_content_wrapper">
                <img src={img} alt=""/>
                <div className="plate_text_wrapper">
                    <h2>Мюзикл Буратино</h2>
                    <h6>Музыкальное цирковое шоу для всей семьи</h6>
                    <p>Знаменитая детская повесть А.Н. Толстого о приключениях деревянного мальчика и силе настоящей дружбы, способной победить любое коварство.
                    </p>
                </div>
            </div>
        )
    }
}

export default Buratino;