import React from 'react';

import './index.css';
import {Flake1, Flake2} from "./flakes";

class Footer extends React.Component {
    constructor(props) {
        super();
    }

    render() {

        return(
            <div className="footer_wrapper">
                <div className="content_centrer">
                    <Flake1 />
                    <Flake2 />

                    <div className="footer_text_wrapper">
                        <div className="footer_left">
                            <p>
                                В гостях у Дедушки Мороза<br />
                                ИП Москвитин С.Н.<br />
                                ОГРНИП 321774600022255<br />
                                По всем вопросам пишите на почту <a href="mailto:dedmorozonline@w-hub.ru">dedmorozonline@w-hub.ru</a><br />
                                Телефон:<br />
                                +7 (499) 444-60-57 ( с 11 до 18 МСК)
                            </p>
                        </div>

                        <div className="footer_right">
                            <p><a rel="noopener noreferrer" target="_blank" href="https://w-hub.ru">Выполнено на платформе W-Hub </a></p>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default Footer;