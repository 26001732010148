import React from 'react';

import './index.css';
import {Flake, Step1, Step2, Step3, Step4, Step5} from "./steps-icons";

class RoadMap extends React.Component {
    constructor(props) {
        super();
    }

    render() {

        return(
            <div className="road_map_wrapper">
                <div className="content_centrer">
                    <div className="flake"><Flake /></div>
                    <h1>Как это работает?</h1>

                    <div className="steps">
                        <div className="step">
                            <div className="step_icon">
                                <Step1 />
                            </div>
                            <div className="step_text">
                                Выбери<br />сеанс
                            </div>
                        </div>
                        <div className="step">
                            <div className="step_icon">
                                <Step2 />
                            </div>
                            <div className="step_text">
                                Заполни<br />анкету
                            </div>
                        </div>
                        <div className="step">
                            <div className="step_icon">
                                <Step3 />
                            </div>
                            <div className="step_text">
                                Выполни задание<br />Дедушки Мороза
                            </div>
                        </div>
                        <div className="step">
                            <div className="step_icon">
                                <Step4 />
                            </div>
                            <div className="step_text">
                                Отправь<br />задание
                            </div>
                        </div>
                        <div className="step">
                            <div className="step_icon">
                                <Step5 />
                            </div>
                            <div className="step_text">
                                Жди<br />ответ
                            </div>
                        </div>

                        <div className="ball"> </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default RoadMap;