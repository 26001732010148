import React from 'react';
import Header from "./components/header";

import './styles/misc.css';
import './styles/layout.css';
import Plates from "./components/plates";
import RoadMap from "./components/road-map";
// import Form from "./components/form";
import Faq from "./components/faq";
import Footer from "./components/footer";


import dayjs from 'dayjs';

dayjs().locale('ru').format();

class App extends React.Component {
    constructor(props) {
        super();

        this.state = {
            formAnchor: null
        }
    }

    scrollToForm = () => {
        if (this.state.formAnchor) {
            this.state.formAnchor.scrollIntoView({behavior: 'smooth'});
        }
    };

    setFormAnchor = (anchor) => {
        this.setState({formAnchor: anchor});
    };

    render() {
        const t = this;

        const addStyle = {height: '90px'};

        return (
            <div className="app_wrapper">
                <Header scrollToForm={t.scrollToForm} />
                <div style={addStyle}> </div>
                <Plates />
                <RoadMap />
                <div className="plug">
                    <p>Прием заявок завершен!</p>
                </div>
                {/*<Form setFormAnchor={t.setFormAnchor} />*/}
                <Faq />
                <Footer />
            </div>
        )
    }

}

export default App;
