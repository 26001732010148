import React from 'react';
import Background from "./background";

import './index.css';
// import {Calendar, Cam} from "../../icons";
import {Flake1, Flake2} from "./flakes";

class Header extends React.Component {
    constructor(props) {
        super();
    }

    render() {
        const p = this.props;

        return(
            <div className="header_wrapper">
                <div className="ellipse_1"> </div>
                <div className="content_centrer">
                    {/*<button className="scroll_to_form_button" onClick={p.scrollToForm}>Заполнить анкету</button>*/}
                    <div className="header_content">
                        <div className="afterwords">
                            <p>Приём заявок завершен!
                                <br /><br />
                                Увидимся через год!
                                <br /><br />

                                Для просмотра своих видеопоздравлений нажмите на волшебную кнопку:</p>
                            <a href="https://w-hub.ru/dedmorozonline"><button>Волшебная кнопка</button></a>
                        </div>

                        {/*<h6><Cam /><span>онлайн-шоу</span></h6>*/}
                        {/*<h1>В гостях у&nbsp;Дедушки Мороза</h1>*/}
                        {/*<div className="date"><Calendar /><span>с 20 декабря 2021 по 7 января 2022</span></div>*/}
                    </div>

                    <div className="ded"> </div>

                </div>
                <Flake1 />
                <Flake2 />
                <Background />
            </div>
        )
    }
}

export default Header;