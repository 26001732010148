import React from 'react';

class Video extends React.Component {

    render() {
        return(
            <iframe title="teaser" width="560" height="315" src="https://www.youtube.com/embed/mi3IHAJ4kIg" frameBorder="0"
                    allowFullScreen> </iframe>
        )
    }
}

export default Video;