import React from 'react';

import './index.css';
import {VideoPreviewBtnPlay} from "../../icons";
import ModalWrapper from "../modal";
import Alisa from "./modal-content/alisa";
import IzGorod from "./modal-content/izumrudniy_gorod";
import Video from "./modal-content/video";
import Buratino from "./modal-content/buratino";
import Ded from "./modal-content/ded";

class Plates extends React.Component {
    constructor(props) {
        super();

        this.state = {
            modalContent: null
        }
    }

    handleClose = () => {
        this.setState({modalContent: null})
    };

    render() {
        const t = this;
        const { modalContent } = t.state;

        const contentChunks = {
            buratino: <Buratino onChange={t.handleClose} />,
            ded: <Ded onChange={t.handleClose} />,
            alisa: <Alisa onChange={t.handleClose} />,
            izumrudniy_gorod: <IzGorod onChange={t.handleClose} />,
            video: <Video onChange={t.handleClose} />
        };

        const middled = modalContent === 'video';

        const modal = modalContent ? <ModalWrapper middled={middled} rounded={middled} noPadding={true} onChange={t.handleClose} modalOpenName="modalContent" content={contentChunks[modalContent]} /> : '';

        return(
            <div className="plates_wrapper">
                <div className="content_centrer">
                    <div className="big_plate_wrapper">
                        <div className="white_right_side"> </div>
                        <div className="gradient_top_wrapper">
                            Здравствуй, мой дорогой Друг! В этот Новый год я бы хотел тебя удивить одним сюрпризом. Чтобы получить его, тебе нужно принять участие в моей новогодней игре, выполнить задание и получить в подарок билет на онлайн-шоу.
                        </div>
                        <div className="white_bottom_wrapper">
                            <div className="ded_userpic"><i> </i></div>
                            <div className="ded_name">
                                Дедушка Мороз
                                <span>Великий Устюг</span>
                            </div>
                        </div>
                    </div>

                    <div className="plates_row">
                        <div className="plate" onClick={() => t.setState({modalContent: 'buratino'})}>
                            <div className="plate_pic plate_pic_buratino"> </div>
                            <h2>Мюзикл Буратино</h2>
                            <h3>Музыкальное цирковое шоу для всей семьи</h3>
                        </div>
                        <div className="plate" onClick={() => t.setState({modalContent: 'ded'})}>
                            <div className="plate_pic plate_pic_ded"> </div>
                            <h2>Персональное поздравление</h2>
                            <h3>Дед Мороз обратиться к ребенку по имени!</h3>
                        </div>
                        <div className="plate plate_video" onClick={() => t.setState({modalContent: 'video'})}>
                            <div className="pv_bg"> </div>
                            <div className="btn_play">
                                <div className="btn_play_bg"> </div>
                                <VideoPreviewBtnPlay />
                            </div>

                            <div className="pv_text">
                                Персонализированное видео: <span>пример поздравления от Дедушки Мороза</span>
                            </div>
                        </div>
                    </div>
                </div>

                {modal}
            </div>
        )
    }
}

export default Plates;