import React from 'react';
import img from './images/alisa.jpg';

class Alisa extends React.Component {

    render() {
        return (
            <div className="plate_content_wrapper">
                <img src={img} alt=""/>
                <div className="plate_text_wrapper">
                    <h2>Алиса</h2>
                    <h6>Сказочное представление для всей семьи</h6>
                    <p>«Алиса» объединяет несколько жанров: музыкальный театр (все вокальные партии исполняются актерами
                        вживую!), современный балет, цирковое и акробатическое искусство.</p>
                    <p>
                        Спектакль рассказывает увлекательную историю по мотивам классического произведения Льюиса Кэрролла, сказку о
                        заскучавшей девочке Алисе, которая провалилась в кроличью нору и оказалась в Стране Чудес, где
                        ее ожидало множество невероятных приключений.
                    </p>
                </div>
            </div>
        )
    }
}

export default Alisa;