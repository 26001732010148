import React from 'react';
import img from './images/ded.png';

class Ded extends React.Component {

    render() {
        return (
            <div className="plate_content_wrapper">
                <img src={img} alt=""/>
                <div className="plate_text_wrapper">
                    <h2>Личное поздравление от Деда Мороза</h2>
                    <h6>Во время поздравления Дед Мороз несколько раз обратится к ребенку по имени</h6>
                    <p>Именное видео поздравление С НОВЫМ 2022 ГОДОМ ОТ ДЕДА МОРОЗА с фотографией вашего ребенка.
                    </p>
                </div>
            </div>
        )
    }
}

export default Ded;