import React from 'react';
import img from './images/izumrudniy_gorod.gif';

class IzGorod extends React.Component {

    render() {
        return (
            <div className="plate_content_wrapper">
                <img src={img} alt=""/>
                <div className="plate_text_wrapper">
                    <h2>Изумрудный город</h2>
                    <h6>Музыкально-цирковой спектакль</h6>
                    <p>Элли и ее друзья вновь ждут гостей, чтобы вместе пережить незабываемые приключения на пути к
                        заветному городу и к исполнению своих желаний!</p>
                    <p>
                        Захватывающий музыкально-цирковой спектакль по мотивам любимой сказки сочетает в себе зрелищные
                        цирковые номера, яркие вокальные партии, эксклюзивные декорации и костюмы, и призван погрузить
                        каждого в атмосферу настоящих чудес!</p>
                </div>
            </div>
        )
    }
}

export default IzGorod;